import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { useDataStoreLocalStorage } from '../machine/use-data-store-local-storage'

import en from './locales/en/translation.json'
import es from './locales/es/translation.json'
import it from './locales/it/translation.json'
import fr from './locales/fr/translation.json'

export const initializeI18next = () => {
  const locale = useDataStoreLocalStorage.getState().locale

  i18n.use(initReactI18next).init({
    lng: locale,
    fallbackLng: 'en',
    // debug: true,
    resources: {
      en: {
        translation: en
      },
      es: {
        translation: es
      },
      it: {
        translation: it
      },
      fr: {
        translation: fr
      }
    }
  })
}

// const locale = useDataStoreLocalStorage.getState().locale

// i18next.use(initReactI18next).init({
//   lng: locale,
//   // lng: 'en',
//   fallbackLng: 'en',
//   debug: true,
//   resources: {
//     en: {
//       translation: en
//     },
//     es: {
//       translation: es
//     }
//   }
// })

export default i18n
